import welcomeBonusBanner from './assets/1.png';
import cashbackBonusBanner from './assets/0.png';
import referWinBonusBanner from './assets/2.png';
import dailyLoginBanner from './assets/daily-login-bonus.jpeg';

type BonusItem = {
  id: string;
  title: string;
  description: string;
  preview: string;
  content: string;
};

export const bonuses: BonusItem[] = [
  {
    id: 'welcome-bonus',
    title: 'GET 100% WELCOME BONUS',
    description: '',
    preview: welcomeBonusBanner,
    content: `
      <h1>GET 100% WELCOME BONUS</h1>
      <p><img src=${welcomeBonusBanner} /></p>
      <ul>
        <li>Register your player account with Us</li>
        <li>Make your first deposit of upto KES.5000</li>
        <li>You will receive a bonus equivalent to your deposit</li>
        <li>Your bonus will be credited to your player-bonus account</li>
        <li>The bonus is exclusively available on sports markets</li>
        <li>To wager the bonus;
          <ul>
            <li>a. To wager the bonus;</li>
            <li>b.	The minimum odds of each team should to be 1.5 odds</li>
            <li>c.	The overall minimum odds should be 8.99 odds</li>
          </ul>
        </li>
        <li><b>The bonus expires after 7 days of making the initial first deposit</b></li>
      </ul>
    `,
  },
  {
    id: 'cashback-bonus',
    title: 'GET 10% CASH BACK',
    description: 'KEEP WINNING WITH PATAPESA CASHBACK',
    preview: cashbackBonusBanner,
    content: `
      <h1>GET 10% CASH BACK</h1>
      <p><img src=${cashbackBonusBanner} /></p>
      <h2>KEEP WINNING WITH PATAPESA CASHBACK</h2>
        <ul>
          <li>Make a deposit to credit your account</li>
          <li>Start playing either casino or sports</li>
          <li>In case you lose without making any withdrawal, you will receive 10% of your initial deposit</li>
          <li>The 10% cash back will be credited instantly to your sports or casino bonus account</li>
          <li>The cash back is playable without wagering restrictions</li>
          <li>Play now and win with Us.</li>
        </ul>
    `,
  },
  {
    id: 'refer-win-bonus',
    title: 'Refer & Win',
    description: 'Join our free affiliate program and earn big!',
    preview: referWinBonusBanner,
    content: `
      <h1>REFER & WIN</h1>
      <p><img src=${referWinBonusBanner} /></p>
      <p>Join our free affiliate program and earn big!</p>
        <ul>
          <li>Join our free affiliate program and earn big!</li>
          <li>You can refer punters by sharing your link or promo code on all your social media platforms i.e. on your timeline, status and groups that you are in.</li>
          <li>You can also refer punters through ground registration.</li>
        </ul>
    `,
  },
  {
    id: 'daily-login-bonus',
    title: 'DAILY LOGIN BONUS',
    description: 'Get your daily bonus and win!',
    preview: dailyLoginBanner,
    content: `
      <h1>DAILY LOGIN BONUS</h1>
      <p><img src=${dailyLoginBanner} /></p>
      <ul>
        <li><b>1. General</b>
          <ul>
            <li>1.1. <b>Daily login bonus</b> ("Promotion") is organized by <b>Patapesa.ke</b>. </li>
            <li>1.2. By participating in this promotion, players agree to abide by these Terms & Conditions.</li>
            <li>1.3. The promotion runs everyday <b>(local time)</b>.</li>
          </ul>
        </li>
        <li><b>2. Eligibility</b>
          <ul>
            <li>2.1. The promotion is open to all registered <b>Patapesa.ke</b> players aged <b>18 years and above</b>. </li>
            <li>2.2. Only verified accounts with a valid phone number.</li>
          </ul>
        </li>
        <li><b>3. Promotion Details </b>
          <ul>
            <li>3.1. The daily <b>login bonus</b> offers players a <b>bonus ranging from KES 10 to KES 10,000</b> during the promotional period.</li>
            <li>3.2. To claim the <b>daily login Bonus</b>, players must use the promo code indicated on the site.</li>
            <li>3.3. <b>The bonus is credited instantly</b> after the promo code is applied.</li>
            <li>3.4. After claiming the bonus, the punter must make a deposit <b>equal to the awarded bonus</b> before placing bets.</li>
            <li>3.5. <b>The maximum winnings from the bonus are capped at KES 20,000.</b> Any amount exceeding this limit will not be credited.</li>
          </ul>
        </li>
        <li><b>4. Bonus Wagering Requirements</b>
          <ul>
            <li>4.1. The awarded bonus must be placed in full on a multi-bet of at least 5games with cumulative odds of 9.99 or higher.</li>
            <li>4.2. Each selection within the multi-bet must have minimum odds of 1.5 or more.</li>
            <li>4.3. Immediately after claiming the bonus, the punter must deposit the amount equal to the awarded bonus and place a bet with minimum odds of 2.00 with the deposit, before using the bonus claimed.</li>
            <li>4.4. If a punter places bets using only the bonus without making the required deposit, the bet will go through. However, any winnings will remain in the bonus account. </li>
            <li>4.5. If a punter divides the bonus and places multiple bets, the winnings will count but will remain in the bonus account.</li>
            <li>4.6. The new winnings become the new baseline, and the punter must deposit an amount equal to the new winnings to unlock them. </li>
            <li>4.7. A deposit made after winning will NOT count toward unlocking the winnings. The required deposit must be made immediately after claiming the bonus. </li>
            <li>4.8. The initial wagering requirements remain in effect, even after winnings are generated from divided bonus bets. </li>
            <li>4.9. Any misuse of the bonus (bonus arbitrage) especially on two-way markets will forfeit the winnings immediately. </li>
          </ul>
        </li>
        <li><b>5. Deposit Winnings & Withdrawals</b>
          <ul>
            <li>5.1. <b>Deposit winnings are NOT tied to the bonus and can be withdrawn instantly.</b></li>
            <li>5.2. Any winnings from the bonus will be credited to the player's main account after meeting all wagering requirements. </li>
          </ul>
        </li>
        <li><b>6. Fraud & Abuse</b>
          <ul>
            <li>6.1. <b>Patapesa.ke</b> reserves the right to cancel or revoke bonuses in case of suspected fraudulent activities, multiple accounts, or abuse of the promotion. </li>
            <li>6.2. Accounts found in violation of these terms will be suspended or permanently banned.</li>
          </ul>
        </li>
        <li><b>7. Amendments & Termination</b>
          <ul>
            <li>7.1. <b>Patapesa.ke</b> reserves the right to modify, suspend, or terminate the promotion at any time without prior notice. </li>
            <li>7.2. Any changes will be communicated via the official website and social media channels.</li>
          </ul>
        </li>
      </ul>
    `,
  },
];

export const bonusesUA: BonusItem[] = [];

export const bonusesEN: BonusItem[] = [];

export const bonusessListByLang: Record<string, BonusItem[]> = {
  default: bonuses,
  'en': bonuses,
};
