export const rulesList = [
  {
    id: 'terms',
    title: 'TERMS AND CONDITIONS',
    content: `
      <h1>TERMS AND CONDITIONS</h1>
      <p>
          Everything contained in these Terms (see Section 1.2 below for how "Terms" is
          defined) is important and should be read by you, but we would like to draw your
          attention in particular to the following Sections in these General Terms and
          Conditions (“General Terms and Conditions” is defined in Section 1.2 below)
          and the important terms and conditions that they contain. These Sections in
          particular have the potential to affect your interests. It is important that you
          read the Sections themselves and not rely on the short summaries below:
        </p>
        <p>
          By registering for an Account (defined in Section 4.1 below) and/or accessing
          or using any part of the Services (defined in Section 1.1 below) you are entering
          into a legally binding contract. That contract is between you and us and it incorporates
          all the terms, conditions, rules and documents forming the “Terms” (explained and
          defined in Section 1.2 below). Please read Section1 carefully.
        </p>
        <p>
          You must be 18 or over and meet certain other eligibility criteria and
          requirements to register for an Account and use the Services. It is also
          important that you determine whether your use of the Services is illegal in your
          home jurisdiction or (if different) your current location, and you may not use
          the Services if this is the case. Please read Section4 carefully.
        </p>
        <p>
          We will need to complete our Verification Checks (defined in Section 6.1) before
          you can do certain things in connection with your Account. We may also run
          Verification Checks on an ongoing basis after your Account has been opened. If we’re
          unable to complete our Verification Checks to our reasonable satisfaction, we will have
          the right to suspend and, ultimately, to close your Account, and we may pass on any
          necessary information to the relevant authorities. Please read Section6 carefully.
        </p>
        <p>
          You may only register for one Account. If we discover that you have opened and/or
          operated more than one Account (which we call a “Duplicate Account”), we will have
          certain rights, including the right to close your Duplicate Account(s) (and in some
          circumstances your original Account too) and to void Transactions (defined in Section 4.1
          below), Bonuses (defined in Section 13 below) and/or winnings. Please read Section
          8 carefully.
        </p>
        <p>
          Withdrawing your funds: You will usually be able to withdraw your available balance
          at any time, but there are certain circumstances in which we will be entitled to delay or
          withhold withdrawals. Please read Section11 carefully.
        </p>
        <p>
          Maximum winnings limits apply to our Services: This means that, regardless of the
          amount you stake and no matter how much you appear to have ‘won’, you will only be
          entitled to receive up to the maximum winnings limit. Please read Section 11.5 carefully.
        </p>
        <p>
          We do not have to accept your bets: We are entitled to decline to accept all, or part,
          of any Transaction (defined in Section 4.1). If we decline all or part of a Transaction, we
          may offer a different/amended Transaction (e.g., on different odds) in place of the
          declined part of the Transaction, which part you are free to accept or decline as you wish.
          We do not have to tell you our reasons for taking these actions. Please read Section
          12.1 carefully.
        </p>
        <p>
          We may suspend markets/settlement and/or declare Transactions void in
          certain circumstances: such as, for example, where the integrity of an event has been
          called into question, or we suspect that the Transaction has been affected by match fixing,
          price or pool manipulation, other forms of cheating or other suspicious activity. Please
          read Section14 carefully.
        </p>
        <p>
          We will not tolerate fraudulent activity, cheating or any other “Prohibited
          Activities” (defined in Section 16 below). If you do any of these things, we will have
          certain rights, including the right to suspend and, ultimately, close your Account, to void
          relevant Transactions and/or to withhold funds (including winnings). Please read Section
          16 and Section17 carefully.
        </p>
        <p>
          Errors, Malfunctions, and Interruptions: If any part of the Services suffers an
          Error, Malfunction, or Interruption (as each of those terms are defined in
          Section 22 below) we have certain rights including (but not limited to) the right to
          suspend the affected event/market while we investigate and/or void affected
        </p>
        <p>
          Transactions. This includes circumstances where the problem is not obvious to you or us
          and is only discovered by us when we check or verify the result of a Transaction and/or
          gameplay retrospectively. Please read Section22 carefully.
        </p>
        <p>
          In certain circumstances, our liability to you is limited or excluded: When you
          access and use the Services, it is important that you understand what we are liable for,
          when limits to our liability will apply (and what those limits are), and what we are not
          liable for. It is also important that you understand that we will not be liable to
          you under these Terms, and nor do we owe you a duty of care, in respect of our
          compliance or otherwise with any regulatory obligations that we may be under
          from time to time, including those relating to 'responsible gambling', 'social
          responsibility' or similar. Please read Section 23 carefully.
        </p>
        <p>
          Your obligation to compensate us. You may be required to compensate us if we suffer
          losses because you have engaged in a Prohibited Activity. Please read Section
          24 carefully.
        </p>
        <p>
          We may sometimes take actions in relation to your Account for legal and/or
          regulatory reasons including where required to do so by the terms of our licence
          and/or a regulatory authority. These actions include running certain Verification
          Checks, restricting/closing your Account, and preventing you from taking up Bonuses
          and/or completing Bonuses. Please read Sections 6, 13.5, and 17.5 carefully.
        </p>
        <h2>Contents</h2>
        <ul>
          <li>1. Introduction - These General Terms and Conditions and Other Important Terms that Apply to You</li>
          <li>2. Who We Are</li>
          <li>3. Changes To These Terms and/or the Services</li>
          <li>4. Account Eligibility and Registration</li>
          <li>5. Account Opening</li>
          <li>6. Account Verification</li>
          <li>7. Account Security</li>
          <li>8. Duplicate Accounts</li>
          <li>9. Dormant Accounts</li>
          <li>10. Deposits, Currency Exchange, and How Your Funds Are Held and Protected</li>
          <li>11. Withdrawals</li>
          <li>12. Placing Transactions and Transaction Confirmation</li>
          <li>13. Promotions, Bonuses and Offers</li>
          <li>14. Transaction Settlement and Payouts, Including Our Right to SuspendMarkets/Events</li>
          <li>15. Sports and Special Event Betting</li>
          <li>16. Prohibited Activities</li>
          <li>17. Suspension, Limitation, and Closure of Your Account by Us</li>
          <li>18. Closing Your Own Account</li>
          <li>19. Your Equipment</li>
          <li>20. Software and Technology</li>
          <li>21. Third Party Content</li>
          <li>22. Malfunctions, Errors and Interruptions</li>
          <li>23. Our Liability to You</li>
          <li>24. Your Responsibility to Us, Our Right of Set-Off</li>
          <li>25. Responsible Gambling</li>
          <li>26. Intellectual Property Rights</li>
          <li>27. Privacy and Cookies</li>
          <li>28. Other Important Legal Terms</li>
          <li>29. Complaints and Disputes, Governing Law and Jurisdiction</li>
        </ul>

        <h2>
          1. Introduction - These General Terms and Conditions and Other Important
          Terms That Apply to You
        </h2>
        <ul>
          <li>
            1.1 When you register for an Account with us ( “Account” is defined in Section 4.1
            below) and/or use or access any of the betting, gaming and/or gambling products or
            services (including the patapesa.ke website and mobile application), and/or any other
            websites or applications that we own or operate (together the “Services ”), you will be
            entering into a legally binding agreement with us.
          </li>
          <li>
            1.2 Not all of the terms and conditions that apply between you and us are set out in
            these General Terms and Conditions (the “General Terms and Conditions”) – some of
            them are contained in separate documents in order to avoid these General Terms and
            Conditions becoming too long. We cross-refer to these other documents and provide
            you with links to them below. They are still important terms which form part of the legal
            agreement between you and us, and you should read and understand them just like the
            rest of these General Terms and Conditions. These cross-referenced terms which
            (together with these General Terms and Conditions) form the binding agreement
            between you and us (which we refer to in this document as the/these "Terms") include
            the following :
            (i) in addition to the general Bonus terms set out in Section 13 below, the specific
            terms/rules for any Bonuses that we offer from time to time, which terms/rules will be
            available on the relevant Bonus page/s of the Website (“Bonus/es” is defined in Section
          </li>
          <li>
            13.1 below).
          </li>
          <li>
            1.3 In the event of any inconsistency between these General Terms and Conditions
            and the terms listed above, these General Terms and Conditions will (unless stated
            otherwise) to that extent prevail.
          </li>
          <li>
            1.4 Please read the Terms carefully before registering for an Account or otherwise
            accessing any part of the Services. If you do not accept the Terms, you may not use,
            visit or access any part of the Services.
          </li>
        </ul>
        <h2>
          2. Who We Are
        </h2>
        <ul>
          <li>
            2.1 References in these Terms to “Patapesa”, “we”, "our " or “us” in relation to:
            (a) Sportsbook and Fantasy Sports
          </li>
          <li>
            2.2 As we made clear in the "Important Information” section at the beginning of
            these General Terms and Conditions: notwithstanding our regulated status, we will not
            be liable to you under these Terms, and nor do we owe you a duty of care, in respect of
            our compliance or otherwise with any regulatory obligations that we may be under from
            time to time, including those relating to 'responsible gambling', 'social responsibility' or
            similar.
          </li>
        </ul>
        <h2>
          3. Changes To These Terms and/or the Services
          Changes to these Terms
        </h2>
        <ul>
          <li>
            3.1 We may change the Terms from time to time for a number of reasons including
            to comply with applicable laws and regulations (including without limitation for the
            purpose of complying with anti-money laundering laws and regulations), to reflect
            changes to the Services, for security purposes, and/or to reflect changes in our
            business practices or operations.
          </li>
          <li>
            3.2 Minor or insubstantial changes may be made at any time and will become
            effective immediately on posting to this (or any other relevant) page and accordingly
            you are advised to review the Terms on a regular basis.
          </li>
          <li>
            3.3 Material changes will be notified to you reasonably in advance of such changes
            taking effect, either by means of an in-Service notification or via email to your
            registered email address. You must confirm acceptance of the material changes before
            these changes come into effect and you can only continue to play once the material
            changes to these terms have been accepted.
          </li>
          <li>
            3.4 If you do not agree to any change in the Terms, you should immediately stop
            using the Services and close your Account.
            Changes to the Services
          </li>
          <li>
            3.5 We may make changes to, or modify, correct, suspend, add to, remove from, or
            discontinue, any aspect of the Services (or any part of them) from time to time without
            liability to you.
          </li>
        </ul>
        <h2>
          4. Account Eligibility and Registration
        </h2>
        <ul>
          <li>
            4.1 To use the Services and to make any bets, wagers, stakes, and/or similar
            transactions (together “Transactions”), you must first register and be accepted for an
            Account with us (“Account”).
          </li>
          <li>
            4.2 To be eligible to open an Account and use the Services, you must meet all of
            the following criteria and by registering for an Account you promise to us that you:
            (a) are over 18 years of age and of legal capacity to enter into these Terms and use
            the Services;
            (b) are acting solely for your own personal use in connection with your Account and
            your use of the Services (i.e., you are not acting on behalf of any other person or
            entity);
            (c) are not self-excluded from gambling and are not otherwise prohibited for any
            reason from gambling or from using the Services;
            (d) do not already have an Account with us (see Section 8 below for more
            information about Duplicate Accounts);
            (e) accept that there is a risk of losing money when using the Services and you are
            responsible for any such loss;
            (f) are not a Patapesa employee or related to a Patapesa employee;
            (g) are not senior management of a Patapesa third party supplier; and
            (h) have not previously had an Account with us (or any member of our corporate
            group) which we (or that member) have restricted or closed for any reason.
          </li>
          <li>
            4.3 Access and/or use of the Services may be illegal for people who are residents
            of, or are located in, certain countries. You are solely responsible for determining
            whether your access and/or use of the Services is prohibited by applicable laws in your
            home country or (if different) in your location and you may not access and/or use the
            Services if this is the case.
          </li>
        </ul>
        <h2>
          5. Account Opening
        </h2>
        <ul>
          <li>
            5.1 To register for an Account, you must complete the required registration form by
            providing certain personal information.
          </li>
          <li>
            5.2 All information supplied by you when registering for an Account must be
            complete, accurate, and not misleading in all respects.
          </li>
          <li>
            5.3 You must keep your registration and Account details up to date. This, and your
            Account information, may be amended in the “My Account” section when logged into
            your Account or by contacting us. It is particularly important that you update us about
            any information that affects your use of the Services (for example, your payment
            method and contact details) as quickly as possible. If you require any assistance,
            please Contact Us.
          </li>
          <li>
            5.4 By registering for an Account, you promise and confirm to us that you meet the
            eligibility criteria in Section 4 above and that all information you provide when
            registering for an Account (and subsequently) is complete, accurate, and not
            misleading.
          </li>
          <li>
            5.5 We are not obliged to accept the registration for an Account.
          </li>
        </ul>
        <h2>
          6. Account Verification
        </h2>
        <ul>
          <li>
            6.1 We are required to undertake certain checks to comply with our legal and
            regulatory obligations – for example to verify your age, identity, address, location,
            financial circumstances, and the source of the funds you deposit and use – and we may
            also undertake other credit, fraud prevention and verification checks (together
            “Verification Checks”). We may ask you to provide various pieces of information and
            supporting documentation to enable us to carry out these Verification Checks, for
            example (but without limitation) a copy of your passport, driving licence, birth
            certificate, financial statements, documents to support the expenditure on your
            Account, proof of address and/or any other documents / evidence that we may
            reasonably require.
          </li>
          <li>
            6.2 Underage gambling is a criminal offence. If we discover (after you've opened an
            Account) that you are under 18 years of age:
            (a) your Account will be closed;
            (b) any deposits you have made will be returned to you;
            (c) all Transactions made via your Account will be void; and
            (d) we may make a report to the relevant regulatory or law enforcement
            authorities.
          </li>
          <li>
            6.4 By registering for an Account, you acknowledge and agree that we are entitled
            to conduct any Verification Checks that we may reasonably require and/or are required
            by applicable laws/regulations. We will carry out Verification Checks during Account
            registration, and we may also need to carry out additional/enhanced Verification Checks
            at certain points afterwards for example where particular financial thresholds are
            reached and/or if certain risk factors arise or change, in each/either case as reasonably
            required for us to comply with our legal and regulatory obligations. You agree to
            promptly provide any information/documentation we may reasonably request in relation
            to such Verification Checks.
          </li>
          <li>
            6.5 Until we have received all requested information/documents and completed our
            Verification Checks to our reasonable satisfaction, we will restrict or place limits on your
            Account in any manner that we reasonably deem to be appropriate including by
            preventing you from accessing the Services, entering into any Transactions, or from
            making withdrawals. If our Verification Checks cannot be completed to our reasonable
            satisfaction, your Account will be suspended and, ultimately, may be permanently
            closed. In this event, we may also be required by applicable regulation and/or a
            relevant authority to withhold funds in your Account.
          </li>
          <li>
            6.6 We may supply the information that you have given us to authorised credit
            reference agencies, fraud prevention agencies, and/or other authorised third parties for
            use in identification and credit checks and for fraud detection and prevention purposes.
            We may also share your information with our regulators.
          </li>
          <li>
            6.7 Your personal information will be processed in accordance with our Privacy
            Policy (see Section 27 below).
          </li>
        </ul>
        <h2>
          7. Account Security
        </h2>
        <ul>
          <li>
            7.1 We allow our customers to choose their own username and password
            combination for their Account as well as a 4 digit passcode for accessing Patapesa apps
            on their mobile devices (together the “Login Credentials”).
          </li>
          <li>
            7.2 You must keep your Login Credentials secret and confidential at all times and
            never share them with someone else.
          </li>
          <li>
            7.3 You are responsible for all Transactions made via your Account and any other
            activities taking place on/via your Account where your Login Credentials have been
            entered correctly and/or if your Account has been accessed via Touch ID, Fingerprint
            log in, Face ID, Passcode, ‘Keep me logged in’, or any similar facility on your device/s
            (and in each of these cases, whether or not authorised by you).
          </li>
          <li>
            7.4 We recommend that you change your Login Credentials regularly. If, at any
            time, you suspect that someone else is aware of your Login Credentials, you should
            change them immediately via your Account settings. If you forget part or all of your
            Login Credentials, please Contact Us.
          </li>
          <li>
            7.5 If you activate Touch ID, Fingerprint log in, Face ID, Passcode, ‘Keep me logged
            in’ and/or any similar facility on your device/s and you suspect that someone else could
            be accessing your Account via any of these facilities, you should immediately disable
            the relevant facilities from your device/s and Contact Us.
          </li>
          <li>
            7.6 If (a) we have authorised you to nominate a specific person to access your
            Account on your behalf (for example to assist you with a visual or other impairment)
            and (b) we have expressly approved that person following satisfaction of our
            verification checks and any other conditions we may have imposed, you will then be
            permitted to allow that person to access your Account provided that you will be solely
            responsible for all Transactions and other activities made or placed by such person
            using your Account details regardless of whether such Transactions/activities have been
            authorised by you or not.
          </li>
          <li>
            7.7 Please note that cardholder details and any other sensitive data should never
            be sent to us by unencrypted email. If you need assistance with sending encrypted
            emails or sending us sensitive data please Contact Us.
          </li>
          <li>
            7.8 The current balance and Transaction history of your Account may be viewed at
            any time once you have logged into your Account.
          </li>
        </ul>
        <h2>
          8. Duplicate Accounts
        </h2>
        <ul>
          <li>
            8.1 You may open only one Account. If we discover or have reason to believe that
            you have opened more than one Account, each such additional Account will be classified
            as a "Duplicate Account". We are entitled, in respect of each Duplicate Account:
            (a) to close such Duplicate Account leaving you with only the original Account
            (unless we have reason – for example if you have deliberately or fraudulently opened
            more than one active Account – to close all your Accounts);
            (b) to treat all Bonuses and/or free bets using the Duplicate Account (and if
            applicable the original Account), and all winnings accrued from such Bonuses and/or
            free bets, as void; and
            (c) to void all unsettled and/or future Transactions on the Duplicate Account (and
            all winnings accrued from such Transactions).
          </li>
        </ul>
        <h2>
          9. Dormant Accounts
        </h2>
        <ul>
          <li>
            9.1 We will treat your Account as “Dormant” if your account has been inactive for a
            consecutive period of at least 365 days. An account is deemed to be inactive during any
            period in which none of the following actions takes place in relation to:
            (a) A successful deposit;
            (b) A Sports bet is placed, or;
            (c) Participation in any Casino, Live Casino, Poker, Games or Bingo product.
          </li>
          <li>
            9.2 If the balance in your Account is zero at the time it is classified as Dormant, it
            will be closed and no fee will apply. However, if you have a positive balance in your
            Account, we will attempt to notify you using the details you provided during your
            registration process (or as later updated by you).
          </li>
          <li>
            9.3 For security reasons, we may need to confirm that the details of your last
            payment method are still correct prior to us being able to return the balance back to it.
            Should it not be possible to return the balance back to your last payment method, you
            will be required to provide details of a bank account in your name that we can verify
            and return the balance to.
          </li>
          <li>
            9.4 You can ‘reactivate’ a Dormant Account by:
            (a) making a successful deposit;
            (b) placing a Sports bet, or;
            (c) participating in any Casino, Live Casino, Poker, Games, or Bingo product.
          </li>
        </ul>
        <h2>
          10. Deposits, Currency Exchange, and How Your Funds Are Held and Protected
          Deposits
        </h2>
        <ul>
          <li>
            10.1 You may only make Transactions of/up to the amount of cleared funds held in
            your Account. This means that, if you want to make Transactions via your Account, you
            must deposit funds into that Account. Transactions which may inadvertently have been
            accepted when the Account did not have sufficient funds to cover the Transaction will be
            void.
          </li>
          <li>
            10.2 Further details of how to deposit can be found on our website. We are entitled to
            withdraw and/or restrict certain payment/deposit methods at any time without notice or
            liability to you.
          </li>
          <li>
            10.3 You may only deposit funds into your Account from a payment source where you
            are the named account holder.
            How your funds are held and protected
          </li>
          <li>
            10.5 By depositing funds into your Account, you direct us and we agree to hold such
            funds, along with any winnings, for the sole and specific purpose of using them (i) to
            place your sporting and gaming stakes; and (ii) settling any fees or charges that you
            might incur in connection with the use of our services (the Purpose). All funds that we
            hold for you are held in separate designated customer bank accounts to be used solely
            for the Purpose and our bank has acknowledged this. We must return the funds to you
            if they are not used for the Purpose. Our bank has acknowledged that your funds are
            held in those accounts solely for the Purpose. The bank has confirmed that it will not
            mix customers’ funds in those accounts with any other, including our own, and that it
            has no claim on the funds itself. In view of the above, in the event of our insolvency,
            your funds are protected. The funds would still belong to you and would not fall into the
            hands of the liquidator.
          </li>
          <li>
            10.6 We are not a bank, and you may not use your Account to hold funds which are
            not intended to be used for the Services. Funds in your Account do not bear interest
            and no credit will be offered by us (meaning that all Transactions must be supported by
            sufficient funds in your Account).
          </li>
        </ul>
        <h2>
          11. Withdrawals
        </h2>
        <ul>
          <li>
            11.1 You may withdraw any cleared real money balance in your Account at any time,
            provided that:
            (a) all payments made into your Account have been confirmed as cleared and have
            not been charged-back, reversed or otherwise cancelled;
            (b) we have completed our Verification Checks to our reasonable satisfaction (see
            Section 6 above). Where we have requested information from you to carry out these
            Verification Checks (see Section 6.3 above), any delay in providing this information may
            cause delay when withdrawing funds;
            (c) we are not required to withhold payments of your deposit and/or winnings by
            applicable law or regulation and/or have not been instructed to do so by a regulator;
            and
            (d) there is currently no ongoing, or completed (save where completed and decided
            in your favour), investigation:
            i) into an Error, Interruption or Malfunction (as those terms are defined in Section 22
            below) involving any aspect of the Services which you have used; or
            ii) where we have reasonable grounds to believe you have engaged in a Prohibited
            Activity (defined in Section 16.2 below) which would entitle us to withhold funds in
            accordance with Section 17.5 below,
            and in the case of (a) and (b) above, you acknowledge and understand that, as we
            made clear in the “Important Information” section at the beginning of these General
            Terms and Conditions, we are ultimately entitled to void any affected Transactions,
            withhold funds (including any winnings) and (in the case of illegal or Prohibited
            Activities) suspend and, ultimately, close your Account.
          </li>
          <li>
            11.2 Withdrawal payments can only be made in the name of and to the registered
            Account holder.
          </li>
          <li>
            11.3 For most payment types, withdrawals can be processed by clicking 'Withdraw'
            within your Account settings, subject to there being sufficient cleared funds in your
            Account and save where these Terms require or entitle us to withhold funds (as
            explained in Section 11.1 above). Authorised withdrawals will usually be processed back
            to the same payment method that was used to deposit funds into the Account save
            that, where the balance is funded from more than one payment method, the withdrawal
            will be split between such payment methods pro rata.
          </li>
          <li>
            11.3 As we made clear in the ‘Important Information” section at the beginning of
            these General Terms and Conditions, maximum winnings limits apply to the Services
            and these limits apply regardless of the amount you have bet or staked on those
            Services. This means that, regardless of the amount you bet/stake and no matter how
            much you appear to have ‘won’ as a result of a Transaction, you will only be entitled to
            receive up to the applicable maximum winnings limit.
          </li>
        </ul>
        <h2>
          12. Placing Transactions and Transaction Confirmation
          Placing Transactions
        </h2>
        <ul>
          <li>
            12.1 We are entitled to decline to accept all, or part, of any Transaction requested by
            you via the Services in our discretion. If we decline all or part of a Transaction, we may
            – but are not obliged to – offer you a different/amended Transaction in place of the
            declined part of the Transaction (e.g., different odds) and you are free to accept or
            decline this different/amended part of the Transaction as you wish. We are not obliged
            to tell you why we have declined to accept a Transaction, or why we have offered a
            different/amended Transaction.
          </li>
          <li>
            12.2 We only accept Transactions made online (including via mobile app) or (in
            exceptional cases where we have agreed in advance) by telephone. Transactions are not
            accepted in any other form (post, email, fax, etc.) and where received will be invalid
            and void - win or lose.
          </li>
          <li>
            12.3 It is your responsibility to ensure the details of your Transactions are correct.
            Once Transactions have been placed they may not be cancelled by you. Transactions
            can only be changed by you using our "Edit Bet" feature, where this is available.
          </li>
          <li>
            12.4 Where Find my Game is used to search and navigate to an In-Play betting
            coupon for a televised game, please ensure you check the betslip before placing a bet.
            It is the responsibility of the customer to ensure the correct coupon is loaded for the
            required event. Once bets/wagers have been placed they may not be cancelled by the
            customer. Bets can only be changed by the customer using our Edit Bet feature, where
            this is available.
          </li>
          <li>
            12.5 Your funds will be allocated to particular Transactions in the order they are
            placed and will not then be available for any other use. Transactions placed using funds
            which have already been allocated to one or more other Transactions will be void.
            Transaction Confirmation
          </li>
          <li>
            12.6 Transactions will not be valid if there are insufficient funds in your Account to
            cover the Transaction.
          </li>
          <li>
            12.7 Any Transaction that you request online or via our app/s will be valid only once
            accepted by our servers and subject to our having received payment in full (see Section
          </li>
          <li>
            12.8). Each valid Transaction will receive a unique Transaction code. We will not be
            liable for the settlement of any Transactions which are not issued with a unique
            Transaction code, because these Transactions are not valid. If you are unsure about the
            validity of a Transaction, please check your Account history, or Contact Us.
          </li>
          <li>
            12.8 Transactions are not valid until we have received cleared payment in full.
            Transactions will automatically be void if we have not received the payment prior to the
            relevant event commencing.
          </li>
          <li>
            12.9 Should a dispute arise, you and we agree that our Transaction log database will
            be the ultimate authority in such matters.
          </li>
        </ul>
        <h2>
          13. Promotions, Bonuses and Offers
        </h2>
        <ul>
          <li>
            13.1 We may offer certain bonuses, offers and/or promotions from time to time via
            the Services, including (without limitation) free bets, bet credits, spins, golden chips,
            cash prizes, tangible prizes, store cards, rebates, cashback (each a “Bonus” and
            together “Bonuses”).
          </li>
          <li>
            13.2 All Bonuses are subject to these Terms, which, as explained in Section 1.2
            above, includes the specific terms/rules for any Bonuses.
          </li>
          <li>
            13.3 All Bonuses are limited to one per person.
          </li>
          <li>
            13.4 We may withdraw, amend or cancel any Bonus at any time and without notice. If
            we do so (and save in the circumstances outlined in Section 13.5 below), any Bonuses
            that you have opted in for or otherwise accrued will still be valid in accordance with and
            subject to the relevant Bonus Terms that applied at the time of their offer.
          </li>
          <li>
            13.5 We may also prevent the take-up of new Bonuses and/or the completion of
            Bonuses for legal and regulatory reasons including but not limited to those relating to
            responsible gambling.
          </li>
        </ul>
        <h2>
          14. Transaction Settlement and Payouts, Including Our Right to Suspend
          Markets/Events
        </h2>
        <ul>
          <li>
            14.1 As explained in Section 1.2 above, Transactions are subject to our maximum
            winnings limits, which form part of these Terms.
          </li>
          <li>
            14.2 The 'To Return' calculation available on the Services is for information purposes
            only, and all Transactions will be calculated using the stake/risk at the odds accepted.
            In multiple bet/parlay Transactions with a void selection(s) the 'To Return' figure is
            reduced accordingly. This includes selections that have been made void where ‘Void if
            player does not start’ is selected at Transaction placement.
          </li>
          <li>
            14.3 If a customer includes a non-runner or void selection in a multiple bet/parlay
            Transaction, the Transaction will be settled on the remaining selections. This includes
            selections that have been made void where ‘Void if player does not start’ is selected at
            Transaction placement.
          </li>
          <li>
            14.4 Winnings from settled Transactions are added to the balance of your Account,
            subject to our rights to void winnings/withhold sums as explained and specified in these
            Terms.
          </li>
          <li>
            14.5 We are entitled to (i) suspend our offering on any event or series of events in
            any of our markets and/or any of our markets as a whole; (ii) withhold settlement,
            and/or (iii) declare Transactions void, in each/either case if we have evidence that any
            of the following has occurred in connection with such event or series of events:
            (a) the integrity of the event/s has/have been called into question;
            (b) the price(s) or pool have been manipulated;
            (c) we have reason to believe automated systems or players have placed the bet;
            (d) we have received erroneous information from feeds in connection with the
            event/s;
            (e) irregular or unusual betting patterns have taken place; or
            (f) match fixing has taken place.
            Evidence of the above may (without limitation) be based on the size, volume or pattern
            of Transactions placed with us across any or all of our betting channels. If no fraudulent
            or unlawful conduct is found to have occurred following completion of our investigation
            (and/or, if applicable, the investigation of the relevant governing body of the sport in
            question (if any)), settlement of the relevant Transactions will be completed. A decision
            given by the relevant governing body will be conclusive.
          </li>
          <li>
            14.6 Where there is evidence of a series of Transactions each containing the same
            selection(s) having been placed by or for the same individual or syndicate of
            individuals, we are entitled to make Transactions void or withhold payment of returns
            pending the outcome of any subsequent investigation.
          </li>
          <li>
            14.7 For events where there is no official 'off' declared, the advertised start time of
            the event will be deemed the 'off'. If for any reason a Transaction is inadvertently
            accepted after an event or match has commenced (other than live In-Play betting
            clearly indicated on the Services), Transactions will stand providing the final outcome is
            not known, and that no participant/team has gained any material advantage (e.g. a
            score, sending off for the other team, etc) at the time the Transaction was placed. If,
            however, the outcome of an event/market is known, we are entitled to void the
            Transaction, win or lose. Disputes over the time a Transaction is placed will be settled
            using the Transaction log. All times stated on the Services and/or referred to by our
            staff relate to UK Time unless stated otherwise.
          </li>
          <li>
            14.8 In-play betting: where we have reason to believe that a Transaction is placed
            after the outcome of an event is known, or after the selected participant or team has
            gained a material advantage (e.g. a score, sending off for the other team, etc.) we are
            entitled to void the Transaction, win or lose.
          </li>
          <li>
            14.9 If for any reason we are unable to validate the outcome of a particular market
            (e.g. due to loss of live pictures), all Transactions will be void, unless settlement of
            Transactions is already determined.
          </li>
          <li>
            14.10 Transactions are settled to fractional prices, irrespective of the odds format used
            for Transaction placement.
          </li>
          <li>
            14.11 You are responsible for reporting your winnings and losses to the tax and/or
            other authorities in your jurisdiction.
          </li>
          <li>
            14.12 Settlement of Transactions on American sports: In all cases Transactions on
            American sports will be settled using statistics and results as provided by each sport's
            governing body (obvious errors excepted). The relevant governing bodies are as
            follows, NFL, NCAAF, CFL, NBA, NCAAB, NHL, MLB, NASCAR, MLS and the PGA tour.
          </li>
        </ul>
        <h2>
          15. Sports and Special Event Betting
        </h2>
        <ul>
          <li>
            15.1 We accept Transactions for sporting and other special events that are advertised
            on the Services. All such Transactions are subject to the rules applicable to each sport,
            e.g. Soccer, Tennis, Boxing, etc. In a match between two teams where the participants
            are named, if any player is replaced prior to the match starting, all match Transactions
            placed prior to the change and relating to the replaced/non-starting player will be void,
            win or lose. Our general betting rules will apply to any situations not covered by the
            special rules.
          </li>
          <li>
            15.2 Where the official result of a market is a tie and no price was quoted for that
            eventuality, all Transactions on participants involved in the tie will be void and stakes
            will be returned.
          </li>
          <li>
            15.3 The result of a match or event will be determined on the day of its conclusion for
            betting purposes, subject to confirmation by the relevant sport's governing body. Any
            subsequent enquiry that may result in an overturned decision will not be recognised by
            Patapesa, and original settlement of Transactions will stand.
          </li>
          <li>
            15.4 If the venue for a sporting event is changed, all Transactions placed based on
            the original venue will be made void and stakes refunded, unless otherwise stated.
          </li>
          <li>
            15.5 Odds On Coupon - For specified sports we have a coupon, listing odds on betting
            opportunities within specified time frames of the next 3/6/12/24/48/72 hours.
            Transactions stand irrespective of fixture time changes.
          </li>
        </ul>
        <h2>
          16. Prohibited Activities
        </h2>
        <ul>
          <li>
            16.1 You may only use the Services and your Account for lawful gambling purposes in
            accordance with these Terms. You must not engage in any activity which we define
            below as a “Prohibited Activity” and you acknowledge and understand that the
            consequences of you doing so will (depending on the specific circumstances) include the
            voiding of any relevant Transactions, the withholding of any attributable winnings, and
            the suspension and/or closure of your Account. You may also be liable to compensate
            us for losses suffered by us as a result of you engaging in a Prohibited Activity (see
            Section 24 below).
          </li>
          <li>
            16.2 The following activities (including any attempt to engage in the following
            activities) are each defined as a “Prohibited Activity”:
            (a) if you become bankrupt;
            (b) if you deliberately attempt to circumvent our Responsible Gambling policies and
            procedures;
            (c) if we discover or have reason to believe that you have used the Services in a
            fraudulent manner and/or for illegal and/or unlawful purposes;
            (d) if you repeatedly exceed our daily Account login limit;
            (e) if we discover or have reason to believe that you have engaged in a betting
            strategy that exploits differences in odds between different sportsbooks, or that
            involves placing bets on all possible outcomes of an event, in either case in order to
            guarantee a profit regardless of the outcome of the event (sometimes referred to as
            ‘arbitrage’ or ‘arbing’);
            (f) if we discover or have reason to believe that you are depositing or withdrawing
            money, or otherwise using the Services, without genuine play/wagering;
            (g) if we discover or have reason to believe that you have used the Services in an
            unfair manner, including if you have cheated, colluded, or taken unfair advantage of us,
            the Services, or any other user of the Services;
            (h) if we discover or have reason to believe that your Account is being used for the
            benefit or on behalf of a third party and/or if you are using the Services other than for
            your own personal and recreational use (including if you are using any third party’s
            Account);
            (i) if we discover or have reason to believe that you are depositing funds into your
            Account without the intention to place Transactions;
            (j) if the name on your Account differs from the name on the payment method
            used to make deposits to your Account;
            (k) if you are using or attempting to use a VPN or similar technology to disguise
            your location;
            (l) if we have received a “charge back”, “denial”, “reversal” and/or "return"
            notification via a deposit mechanism used on your Account;
            (m) if you misuse any Bonuses, including if we discover or have reason to believe
            that you are taking unfair advantage of, or are exploiting or manipulating, any Bonuses
            or you otherwise breach the Bonus Terms;
            (n) if you provide incorrect, incomplete, or misleading information while registering
            for an Account or afterwards;
            (o) if we discover or have reason to believe that you have allowed or enabled
            someone else to use or access your Account (except solely as authorised by us under
            Section 7.6 above);
            (p) if we discover or have reason to believe that you have deliberately or
            fraudulently opened or are using one or more Duplicate Account/s;
            (r) if we discover or have reason to believe that you are prohibited from entering
            into a Transaction by any term of your contract of employment, these Terms, or any
            rule of a sport governing body or other professional body of which you are a member
            and which applies to you;
            (s) where a Transaction is made on the outcome of a race, competition or other
            event or process or on the likelihood of anything occurring or not occurring, and we
            discover or have reason to believe that you know the outcome of that
            race/competition/event/process;
            (t) if we discover or have reason to believe that you are misusing non-public
            information to place a Transaction;
            (u) if we discover or have reason to believe that you are depositing money into
            your Account which originates from criminal and/or other illegal activities;
            (v) if you behave in a defamatory, abusive, obscene, discriminatory, or otherwise
            inappropriate manner towards other users of the Services, our customer support team,
            and/or other staff members/representatives;
            (w) if we discover or have reason to believe that you have used automated systems
            or software to copy and/or extract the whole or any part of the Services, any
            information or data on the Services or any part of it (including, but not limited to,
            results, statistics, sporting data and fixture lists, odds and betting figures), or any other
            information or data on or contained within or as part of the Services and/or its source
            code (sometimes referred to as ‘screen scraping’ or ‘scraping’);
            (x) if we discover or have reason to believe that you have sought to make
            commercial use of the whole or any part of the Services, the information or data on the
            Services (including, but not limited to, results, statistics, sporting data and fixture lists,
            odds and betting figures), or any other information or data on the Services and/or its
            source code;
            (y) if we discover or have reason to believe that you have in any way interfered,
            interrupted, or manipulated the operation of the Services or the normal running of any
            of the Services;
            (z) if we discover or have reason to believe that you have used any third party
            external player assistance programs or software which are designed to provide an
            unfair advantage to customers including (without limitation) the use of artificial
            intelligence, automated players (bots) and/or player assistance software;
            (aa) if we discover or have reason to believe that you have exploited or participated
            in the exploitation of a fault, loophole or error (including any Error, Malfunction or
            Interruption as defined in Section 22 below) in the Services and/or in our or any third
            party's software;
            (bb) if we discover or have reason to believe that you have, or have attempted to,
            sell, transfer and/or acquire any Account/s from other customers; and
            (cc) if you seriously breach these Terms in some other way.
          </li>
        </ul>
        <h2>
          17. Suspension, Limitation, and Closure of Your Account by Us
        </h2>
        <ul>
          <li>
            17.1 We may close, restrict, limit or suspend your Account at any time and without
            notice save as set out below in this Section 17.
          </li>
          <li>
            17.2 We may notify you of an Account closure by us, except where we discover or
            suspect (acting reasonably) that you have engaged in a Prohibited Activity, in which
            case we are entitled to take such action without notice following the process set out
            below.
          </li>
          <li>
            17.3 If we discover or have reasonable grounds to suspect that you have engaged in
            any Prohibited Activity, we will limit or suspend your Account without notification, and
            your Account will remain restricted/suspended while the matter is investigated. You
            agree to cooperate fully with any such investigation. Your funds cannot be accessed or
            withdrawn during periods of suspension, and any balance will remain in the Account.
            We will use reasonable efforts to undertake our investigation within a reasonable
            period, but you acknowledge that any delay on your part to respond to our information
            or other requests will affect our ability to do so.
          </li>
          <li>
            17.4 If our investigation results in our decision (acting reasonably) that the issue has
            been resolved to our satisfaction, we will lift the Account suspension. If our
            investigation results in our decision (acting reasonably) that you have engaged in any
            Prohibited Activity, we will permanently close your Account. In these circumstances,
            your details may be passed on to any applicable regulatory authority or any other
            relevant external third parties.
          </li>
          <li>
            17.5 We may also from time to time be required to restrict, suspend or close
            Accounts for legal or regulatory reasons, including where we are required to do so by a
            competent authority and/or the terms of our licence. Such actions, if applicable, may
            affect your use of your Account.
          </li>
          <li>
            17.6 If we close your Account, any positive real money balance in your Account at the
            time of such closure by us will be paid back to you, except that:
            (a) if you have engaged in illegal activity, we are under no obligation to refund to
            you any money that may be in your Account; and
            (b) if we discover or determine (acting reasonably) that you have participated in
            any Prohibited Activity then we will withhold all or part of the Account balance and/or
            void or recover from your Account deposits, pay-outs, Bonuses, and/or any winnings
            (if/as applicable) in each case to the extent attributable to the relevant Prohibited
            Activity/ies.
          </li>
        </ul>
        <h2>
          18. Closing Your Own Account
          If you want to close your Account, please do so via our Account Closure page.
        </h2>
        <ul>
        </ul>
        <h2>
          19. Your Equipment
        </h2>
        <ul>
          <li>
            19.1 Your computer equipment or mobile device and internet connection may affect
            the performance and/or operation of the Services.
          </li>
          <li>
            19.2 contact us for further information relating to Services accessibility requirements
            and technical issues relevant to use of the Services.
          </li>
        </ul>
        <h2>
          20. Software and Technology
        </h2>
        <ul>
          <li>
            20.1 In order for you to use certain aspects of the Services, you may need to
            download some software (the “Software”).
          </li>
          <li>
            20.2 Also, certain third party product providers may require you to agree to
            additional terms and conditions governing the use of their products. If you do not
            accept those third party terms and conditions, do not use the relevant third party
            software. We do not accept any liability in respect of any third party software.
          </li>
          <li>
            20.3 You are may only use the Software for the purpose of using the Services as
            they’re designed to be used and, save to the extent permitted by applicable law, for no
            other purposes whatsoever.
          </li>
          <li>
            20.4 We grant to you a personal, non-exclusive, non-transferable right to use the
            Software, for the sole purpose of using the Services as they’re designed to be used, in
            accordance with the following provisions.
            (a) You may not: (i) install or load the Software onto a server or other networked
            device or take other steps to make the Software available via any form of "bulletin
            board", online service or remote dial-in or network to any other person; (ii) sub-license,
            assign, rent, lease, loan, transfer or copy (except as expressly provided elsewhere in
            these Terms) your licence to use the Software or make or distribute copies of the
            Software; (iii) enter, access or attempt to enter or access or otherwise bypass our
            security system or interfere in any way (including, but not limited to, robots or similar
            devices) with the relevant products or the Services or attempt to make any changes to
            the Software and/or any features or components thereof; or (iv) copy or translate any
            user documentation provided online or in electronic format. In addition, and except to
            the minimum extent permitted by applicable law in relation to computer programs, you
            are not permitted to: (a) translate, reverse engineer, decompile, disassemble, modify,
            create derivative works based on, or otherwise modify the Software; or (b) reverse
            engineer, decompile, disassemble, modify, adapt, translate, make any attempt to
            discover the source code of the Software or to create derivative works based on the
            whole or on any part of the Software.
            (b) You do not own the Software. The Software is owned and is the exclusive
            property of Patapesa or a third party software provider company (the "Software
            Provider"). Any Software and accompanying documentation which have been licensed
            to us are proprietary products of the Software Provider and protected throughout the
            world by copyright law. Your use of the Software does not give you ownership of any
            intellectual property rights in the Software.
            (c) Subject always to Section 23.1, the Software is provided "as is" and we do not
            warrant that: (i) the Software will meet your requirements; (ii) the Software will not
            infringe any third party’s intellectual property rights; (iii) the operation of the Software
            will be error free or uninterrupted; (iv) any defects in the Software will be corrected; or
            (v) the Software or the servers are virus-free.
            (d) In the event of communications or system errors occurring in connection with
            the settlement of Accounts or other features or components of the Software, neither we
            nor the Software Provider will have any liability to you or to any third party in respect of
            such errors. In the event of such errors, we are entitled to remove all relevant products
            from the Services and take any other action to correct such errors.
            (e) You acknowledge that how you use the Software is outside of our control and
            at your own risk.
            (f) The Software may include confidential information which is secret and valuable
            to the Software Provider and/or us. You are not entitled to use or disclose that
            confidential information other than strictly in accordance with these Terms.
          </li>
          <li>
            20.5 You must not misuse the Services by introducing viruses, Trojans, worms, logic
            bombs or other material which is malicious or technologically harmful. In particular, you
            must not access without authority, interfere with, damage or disrupt the Services or
            any part of it; any equipment or network on which the Services is stored; any Software
            used in connection with the provision of the Services; or any equipment, software or
            website owned or used by a third party. You must not attack our Services via a denialof-service attack. Save where we have not taken reasonable steps, we will not be liable
            for any loss or damage caused by a distributed denial-of-service attack, viruses or other
            technologically harmful material that may infect your computer equipment, computer
            programs, data or other proprietary material due to your use of the Services, the
            Software, or to your downloading of any material posted on it, or on any website linked
            to it.
          </li>
        </ul>
        <h2>
          21. Third Party Content
        </h2>
        <ul>
          <li>
            21.1 We receive feeds, commentaries and content from a number of suppliers.
            Certain third party product providers may require you to agree to additional terms and
            conditions governing the use of their feeds, commentaries and content (including those
            specified in Section 21.2 below). If you do not accept the relevant third party terms and
            conditions, do not use the relevant feeds, commentaries or content.
          </li>
          <li>
            21.2 Where the Services contains links to third party websites and resources, these
            links are provided for your information only. We have no control over the content of
            these sites or resources, and accept no liability for them or for any loss or damage that
            may arise from your use of them. The inclusion of a link to a third party website does
            not constitute an endorsement of that third party’s website, product or services (if
            applicable).
          </li>
        </ul>
        <h2>
          22. Malfunctions, Errors and Interruptions
        </h2>
        <ul>
          <li>
            22.1 We try to provide you with a high-quality betting and gaming platform, but we
            cannot guarantee that the Services will at all times perform correctly. Various kinds of
            mistakes and errors can occur – technical, human and otherwise – and you understand
            and agree that we can only provide the Services to you, and you must accept them, in
            the condition that we make them available from time to time.
          </li>
          <li>
            22.2 In this Section we set out a description of the things that can go wrong and the
            rights that we have in such events. In Section 23 below we set out how we will not be
            liable to you when these (and other) things go wrong.
          </li>
          <li>
            22.3 Below are some key definitions to help you understand this Section:
            A “Malfunction” refers to where an automated process fails to operate as designed or
            intended, for example where a piece of computer software produces a deviation from its
            intended performance specifications or parameters, or where two pieces of software fail
            to interface or communicate with each other in the manner intended or designed, in
            either case so as to produce a result which is not the result that was intended or
            designed. These malfunctions may or may not be apparent to us or to you and they
            may only come to light as and when settlement instructions, game outcomes, winnings
            or cashouts are investigated and analysed retrospectively.
            An “Error” refers to a mistake or error that is not exclusively technical, for example the
            mistaken posting of incorrect odds, the offer of incorrect prices or terms for
            participation in a promotion or gambling opportunity. Errors can arise from human
            mistakes, administrative, operational or systems failures and combinations of all of
            these. Errors include (but aren’t necessarily limited to) so-called ‘palpable’ or ‘obvious’
            errors, for example where:
            · prices/odds/terms offered via the Services are substantially out of the market
            (i.e., significantly different from those available in the general market (including from
            other operators) at the time the Transaction is placed);
            · Transactions are accepted on a market which should have been suspended or
            closed, for example where the event is still in progress (except where ‘in-running’ bets
            are expressly allowed and accepted) or the event in question has already occurred or
            finished (sometimes referred to as ‘late bets’);
            · prices/odds/terms offered at the time a Transaction is made are a clearly
            incorrect given the probability of the event or outcome occurring; or
            · where a market has been settled but it is subsequently discovered that the
            outcome/result is incorrect based on the definition of the count for that market e.g.,
            count for shots on target.
            An “Interruption” refers to an interruption in a gambling Transaction for any reason,
            for example an interruption caused by the failure of the internet or connectivity, or
            severe latency in communications, or technical failure due to some disaster or
            unforeseen event.
          </li>
          <li>
            22.4 Where any of the above occur, we will deal with them as described below and it
            is important that you understand and agree to the following before you use the
            Services, as we made clear in the “Important Information” section at the top of these
            General Terms and Conditions:
            Malfunctions
          </li>
          <li>
            22.5 If as a result of a Malfunction, your Account is credited with winnings that you
            would not have received were it not for that Malfunction, we will have the right to void
            the relevant and any related Transaction(s) and withhold the relevant winnings. This
            applies even if the relevant gambling product or Bonus could have produced the same
            or similar amount of winnings without the intervention of the Malfunction and it applies
            whether the Malfunction was apparent to you or to us or not. If you have withdrawn
            any such winnings, you agree that you will pay them back to us as and when we ask
            you to.
          </li>
          <li>
            22.6 Where any Malfunction is or becomes apparent or obvious to you or you suspect
            that a Malfunction may be occurring, you agree that you will cease your use of the
            relevant Service(s) immediately, and will Contact Us immediately.
            Errors
          </li>
          <li>
            22.7 In the event of an Error, we will have the right to suspend the affect
            event/market while we investigate the problem, and we will resettle Transactions as set
            out below:
            (a) Incorrect price – whether prior to the start of an event, ‘in-play’ or after the
            event, where this type of Error is identified any Transactions will stand and will be
            settled at the BetKali revised price save that, where a revised price is deemed less than
            1/1000, then Transactions will be void.
            Where there is sufficient time before the start of an event, we will endeavour to contact
            you and may in our discretion allow the option of cancelling the Transaction.
            (b) Incorrect Count/Line/Spread/Handicap/Total – where this type of Error is
            identified, any Transactions will stand and will be settled at the count, line, spread,
            handicap or total taken at the Patapesa revised price except for the following situations:
            · Where a revised price is deemed less than 1/1000 then Transactions will be void.
            · Any Transactions placed on a count, line, spread, handicap or total where the
            outcome is already known when the Transactions was placed will be void.
            (c) Incorrect Cash Out and Edit Bet Amount – Transactions cashed out or edited
            by you using our Edit Bet feature, where the Cash Out or Edit Bet amount is incorrect
            (due to an Error in the underlying price), will stand and be resettled at the correct
            amount. Where a selection is Cashed Out following an incorrect result the selection will
            be resettled on the correct result.
            (d) Incorrect Void – If ‘Void if player does not start’ was selected at Transaction
            placement (specific to Goalscorer markets) and a Transaction is incorrectly made void
            where the selected player has actually started the event, the original Transactions will
            stand and will be resettled on the correct outcome.
            (e) Incorrect Fixture - Where an incorrect player or team is quoted within a fixture,
            all Transactions will be void. Such determination to be in Patapesa’s sole but reasonable
            discretion.
            (f) Incorrect Market - If an incorrect market is quoted for any match or event,
            Transactions placed on that market will be void; other markets may also be void. Such
            determination to be in Patapesa’s sole but reasonable discretion.
            (g) Late Bets – Where a ‘late bet’ Transaction is inadvertently accepted, Transactions
            will be settled as follows:
            i. If the event and market is covered In-Play then Transactions will stand at the
            revised price at the time the Transaction was placed (where a revised price is deemed
            less than 1/1000 then Transactions will be void), unless the outcome is already known
            in which case such Transactions will be void.
            ii. If the event or market is not covered In-Play then the Transaction will stand as
            long as the selected participant or team has not gained a material advantage (e.g. a
            score, sending off for the other team etc.). Where a material advantage has been
            gained, we are entitled to void the Transaction, win or lose. Any Transaction placed
            where the outcome is already known, including In-Play, will be void.
          </li>
          <li>
            22.8 If as a result of an Error, your Account is credited with winnings that you would
            not have received were it not for that Error, we will have the right to void the relevant
            Transaction and any related Transaction(s) and withhold the relevant winnings. This
            applies even if the relevant gambling product or Bonus could have produced the same
            or similar amount of winnings without the intervention of the Error and it applies
            whether the Error was apparent to you or to us or not. If you have withdrawn any such
            winnings credited as a result of an Error, you agree that you will pay them back to us as
            and when we ask you to.
          </li>
          <li>
            22.9 Where any Error is or becomes apparent or obvious to you or you suspect that
            an Error may be occurring, you agree that you will cease your use of the relevant
            Service(s) immediately, and will Contact Us immediately.
            Interruptions
          </li>
          <li>
            22.10 Our priority is to ensure that we deal with Interruptions in a way that is fair and
            does not systematically disadvantage our customers.
          </li>
          <li>
            22.11 In relation to betting: we reserve the right to suspend betting markets, void
            Transactions and return stakes to customers.
          </li>
          <li>
            22.12 In relation to gaming:
            (a) where an Interruption occurs after we receive notification of your gamble and
            where you can have no further influence on the outcome of the event or gamble, the
            results of the gamble will stand;
            (b) where an Interruption to a single-participant single stage event occurs before
            an outcome has been generated we will return your stake to your Account;
            (c) for games where there are multiple stages or decision points we will take all
            reasonable steps to restore the game to its last known state prior to the Interruption to
            enable you to complete the game;
            (d) for games with multiple participants (equal chance or otherwise) we will deal
            with these as seems fairest to us (acting reasonably) on a case-by-case basis; and
            (e) for games that are progressive jackpots, progressive jackpot values will be
            restored to their pre-failure state to the extent it is within our control and not the
            games provider.
          </li>
        </ul>
        <h2>
          23. Our Liability to You
        </h2>
        <ul>
          <li>
            23.1 Nothing in these Terms excludes or limits our liability for:
            (a) death or personal injury caused by our negligence;
            (b) fraud or fraudulent misrepresentation; or
            (c) any liability which cannot be excluded or limited under applicable law including
            your statutory rights as a consumer.
          </li>
          <li>
            23.2 Subject always to Section 23.1 above, we will not be responsible to you or any
            third party for any damages, liabilities or losses which are deemed or alleged to have
            arisen out of or in connection with the Services or its content, including arising from or
            in any way connected with:
            (a) losses that were not foreseeable by you and us at the time of you first
            accepted the Terms;
            (b) (if you use the Services for any non-personal/business purposes, which you are
            not permitted to do) any business losses and/or losses to non-consumers (for example
            loss of business, loss of business information, business interruption and loss of profits
            or revenues);
            (c) any use by you of the Services, including where you are in breach of these
            Terms (including any Prohibited Activity on your part) and/or where you use/access the
            Services in breach of applicable local laws (see Section 4.3 above);
            (d) any incomplete, lost or delayed Transactions, other than to the extent caused
            by our failure to use reasonable care and skill;
            (e) damage to your equipment or any loss or corruption of data that results from
            your use of the Services, except that if any defective digital content that we have
            supplied to you damages a device or digital content belonging to you and this is caused
            by our failure to use reasonable care and skill, we will either repair the damage or pay
            you compensation;
            (f) any third party feeds, commentaries or content;
            (g) the accuracy, completeness or currency of any information or data provided by
            us or third parties (including but not limited to prices, runners, times, results, live
            scores or general statistics) via the Services;
            (h) any Error, Malfunction or Interruption, or if for any reason the Services (or any
            part of them) are unavailable at any time or for any period;
            (i) our closure, restriction, limitation, or suspension of your Account in accordance
            with these Terms;
            (j) any delay in performing, or failure to perform, any of our obligations if such
            delay or failure results from events, circumstances or causes beyond our reasonable
            control including (without limitation) any telecommunications network failures, power
            failures, failures in third party computer hardware or software, fire, pandemic,
            lightning, explosion, flood, severe weather, industrial disputes or lock-outs, terrorist
            activity and acts of government or other competent authorities;
            (k) your deliberate circumvention of any responsible gambling measures in place
            (whether put in place by you or by us) (see Section 25 below); or
            (l) any failures or issues that arise due to your equipment, internet connection or
            internet or telecommunication service provider (including, for example, if you are
            unable to place Transactions or view or receive certain information in relation to
            particular events).
          </li>
          <li>
            23.3 As we made clear in the “Important Information” section at the top of these
            General Terms and Conditions, we will not be liable to you under these Terms, and nor
            do we owe you a duty of care, in respect of our compliance or non-compliance with any
            regulatory obligations that we may be under from time to time.
          </li>
          <li>
            23.4 From time to time, all or part of the Services may be unavailable because of our
            maintenance of the Services and/or alteration or amendment of any of the Services and
            we will not be liable to you in these circumstances.
          </li>
          <li>
            23.5 While we try to ensure that the information made available via the Services is
            correct, we cannot (and do not) guarantee the accuracy or completeness of the
            information and material on the Services. The Services may contain typographical
            errors or other inaccuracies, or information that is out of date. We are under no
            obligation to update such information or material. Subject always to Section 23.1
            above, the information and material on the Services is provided “as is”, without any
            conditions, warranties or other terms of any kind.
          </li>
          <li>
            23.6 Subject always to Section 23.1 above, and save in respect of any winnings or
            other sums properly owing to you in accordance with (and subject to) these Terms, our
            total aggregate liability to you under or in connection with these Terms is limited to the
            greater of:
            (a) where the liability relates to a specific Transaction, the value of the applicable
            stake made by in respect of such Transaction;
            (b) the amount of applicable monies, where such monies have been misplaced by
            us.
          </li>
        </ul>
        <h2>
          24. Your Responsibility to Us, Our Right of Set-Off
        </h2>
        <ul>
          <li>
            24.1 We may set off any positive balance on your Account against any amounts owed
            by you to us or any company within the Patapesa.
          </li>
          <li>
            24.2 You agree to compensate us for any costs, charges or losses sustained or
            incurred by us arising from any Prohibited Activities on your part.
          </li>
        </ul>
        <h2>
          25. Responsible Gambling
        </h2>
        <ul>
          <li>
            25.1 We are committed to supporting responsible gambling. We promote gambling as
            an enjoyable leisure activity and we believe that gambling can only remain this way if
            you stay in control and gamble responsibly. However, we know that for some people
            gambling can stop being a harmless leisure activity and become a problem.
          </li>
          <li>
            25.2 We have put in place a number of tools that you can use to manage your
            gambling, such as a budget calculator, deposit limits, reality checks, activity tracker,
            time-out, self-exclusion, and gambling blocks.
          </li>
          <li>
            25.3 For further information, advice and support, please email us:
            support@patapesa.ke
          </li>
          <li>
            25.4
          </li>
          <li>
            25.5 Although we will use our reasonable efforts to enforce our responsible gambling
            policies, we do not accept any responsibility or liability if you nevertheless continue
            gambling and/or seek to use the Services with the intention of deliberately avoiding the
            relevant measures in place (whether put in place by you or by us) and/or we are unable
            to enforce our measures/policies for reasons outside of our reasonable control including
            if you have provided us with details which are not true and accurate in order to
            circumvent any responsible gambling policies or tools.
          </li>
        </ul>
        <h2>
          26. Intellectual Property Rights
        </h2>
        <ul>
          <li>
            26.1 The Services and their contents are protected by international copyright laws
            and other intellectual property rights. The owners of these rights are Patapesa, its
            affiliates and/or other third party licensors.
          </li>
          <li>
            26.2 All product and company names and logos featured on the Services are the
            trade marks, service marks or trading names of their respective owners, including
            (where applicable) Patapesa.
          </li>
          <li>
            26.3 Except to the extent required for the purpose of placing Transactions, no
            part ofthe Services may be reproduced or stored, modified, copied, republished,
            uploaded, posted, transmitted or distributed, by any means or in any manner, or
            included in any website or in any public or private electronic retrieval system or
            service including text, graphics, video, messages, code and/or software.
          </li>
        </ul>
        <h2 id='policy'>
          27. Privacy and Cookies
        </h2>
        <ul>
          <li>
            27.1 Your personal information is processed in accordance with our Privacy Policy, a
            copy of which is available on our website.
          </li>
          <li>
            27.2 Our Cookies Policy – a copy of which is available on our website – explains what
            cookies are, how they are used on the Services, and how to manage their use.
          </li>
        </ul>
        <h2>
          28. Other Important Legal Terms
        </h2>
        <ul>
          <li>
            28.1 If you breach these Terms and we take no action against you, we will still be
            entitled to use our rights and remedies in any other situation where you breach these
            Terms.
          </li>
          <li>
            28.2 The rights and remedies provided by these Terms are cumulative and (unless
            otherwise provided in these Terms) do not exclude any other rights or remedies
            available to us.
          </li>
          <li>
            28.3 If any provision of these Terms is found by any court or body of competent
            jurisdiction to be invalid or unenforceable, the other provisions shall continue to apply.
          </li>
          <li>
            28.4 We may transfer our rights and/or obligations under these Terms to another
            organisation at any time. Without limiting the preceding sentence, we may wish to
            transfer our rights or obligations under these Terms to another company within our
            corporate group or any other legal entity, including if we restructure or there is a sale of
            our business. You agree that we may do so provided that in the case of such a transfer,
            after we notify you of the date on which such transfer takes place, your rights under
            these Terms will be against the new legal entity. If you are unhappy with the change,
            you may close your Account at any time.
          </li>
          <li>
            28.5 These Terms, and your account, are personal to you. You may not transfer your
            rights or obligations under these Terms, or your account, to anyone else.
          </li>
          <li>
            28.6 If we need to notify or contact you under these Terms, we will do so using the
            contact details registered to your Account or via notification on our websites, apps
            and/or or other parts of the Services. If you want to contact us, you can do so
            via Contact Us.
          </li>
        </ul>
        <h2>
          29. Complaints and Disputes, Governing Law and Jurisdiction
        </h2>
        <ul>
          <li>
            29.1 Should there be any claim or dispute arising from a Transaction or otherwise in
            connection with the Services, please follow our internal complaints procedure you can
            contact us. You must exhaust our internal complaints procedure prior to referring your
            complaint to the court of law.
          </li>
        </ul>
    `,
  },

  {
    id: 'age-res',
    title: 'Age restrictions<',
    content: `
      <h1>Age restrictions</h1>
        <p>
          You must be 18 or over and meet certain other eligibility criteria and
          requirements to register for an Account and use the Services. It is also
          important that you determine whether your use of the Services is illegal in your
          home jurisdiction or (if different) your current location, and you may not use
          the Services if this is the case.
        </p>
    `,
  },

  {
    id: 'privacy-policy',
    title: 'Privacy and Cookies',
    content: `
      <h2>
          Privacy and Cookies
        </h2>
        <ul>
          <li>
            1. Your personal information is processed in accordance with our Privacy Policy, a
            copy of which is available on our website.
          </li>
          <li>
            2. Our Cookies Policy – a copy of which is available on our website – explains what
            cookies are, how they are used on the Services, and how to manage their use.
          </li>
        </ul>
    `,
  },

  // {
  //   id: 'rule',
  //   title: '',
  //   content: `
  //     <h2></h2>
  //     <ul>
  //       <li></li>
  //     </ul>
  //   `,
  // },
];
